<template>
	<PopupTwoCol name="smr"
	text="<h3>Projet Steam methane reformer (SMR)</h3><br/><p>Cette unité, construite et opérée par Air Liquide, fournira l’hydrogène nécessaire au fonctionnement de l’unité de désulfuration HDT de VGO. Le process vise à transformer du gaz naturel en hydrogène.</p>">
	<picture slot="thumbnail__1">
		<img class="lazy"
		sizes="(max-width: 1366px) 300px,
		(max-width: 1920px) 410px,
		970px"
		src="static/logo-total.jpg"
		alt="">
	</picture>
</PopupTwoCol>
</template>

<script>
	export default {
		components: {
			PopupTwoCol: () => import('@/components/PopupTwoCol.vue'),
		},
	}
</script>
